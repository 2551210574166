import React, { createContext, useReducer, useEffect } from 'react';

import PropTypes from 'prop-types';

const initialState = {
  loading: false,
  cookiesConfirmed: true,
  language: '',
  successPurchase: false,
  logIn: false,
  lastPurchase: ''
};

export const GlobalStateContext = createContext(initialState);
export const GlobalDispatchContext = createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'CHANGE_LANGUAGE': {
      return {
        ...state,
        language: action.payload
      };
    }
    case 'CHANGE_LOADING': {
      return {
        ...state,
        loading: action.payload
      };
    }
    case 'CONFIRM_COOKIES': {
      return {
        ...state,
        cookiesConfirmed: action.payload
      };
    }
    case 'ADD_COOKIES_DATA_INTO_LOCAL_STORAGE': {
      localStorage.setItem('magnesCookiesConfirmed', true);
      return {
        ...state,
        cookiesConfirmed: true
      };
    }

    case 'SUCCESS_PURCHASE_SNACKBAR': {
      return {
        ...state,
        successPurchase: action.payload
      };
    }

    case 'UPDATE_LAST_PURCHASE': {
      return {
        ...state,
        lastPurchase: action.payload
      };
    }

    case 'LOG_IN': {
      return { ...state, logIn: true };
    }

    case 'LOG_OUT': {
      localStorage.removeItem('token');
      return { ...state, logIn: false };
    }

    default:
      throw new Error('Bad Action Type');
  }
}

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      dispatch({ type: 'LOG_IN' });
    }
  }, [token]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
