/**
 * External dependencies.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';

import { ApolloProvider } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

/**
 * Internal dependencies.
 */
import { client } from './src/apollo/client';
import { CartAppProvider } from './src/context/CartContext';
import { GlobalContextProvider } from './src/context/GlobalContextProvider';
import './src/styles/global.css';

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY);

export const wrapRootElement = ({ element }) => {
  if (typeof window !== 'undefined') {
    return (
      <ApolloProvider client={client}>
        <GlobalContextProvider>
          <CartAppProvider>
            <Elements stripe={stripePromise}>{element} </Elements>
          </CartAppProvider>
        </GlobalContextProvider>
      </ApolloProvider>
    );
  } else {
    return (
      <ApolloProvider client={client}>
        <Elements stripe={stripePromise}>{element} </Elements>
      </ApolloProvider>
    );
  }
};

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    createRoot(container).render(element);
  };
}
