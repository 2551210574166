import React, { createContext, useContext, useState } from 'react';

import PropTypes from 'prop-types';

const defaultState = {
  setCart: () => {},
  cart: undefined
};

const AppContext = createContext(defaultState);

const CartAppProvider = ({ children }) => {
  const [cart, setCart] = useState(defaultState.cart);

  return <AppContext.Provider value={{ cart, setCart }}>{children}</AppContext.Provider>;
};

const useCartAppState = () => useContext(AppContext);

export { CartAppProvider, useCartAppState };

CartAppProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};
